// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import filter from 'lodash/filter'
// import map from 'lodash/map'

// import pushObjectIntoShoppingCart from '../components/contribute-form/reducers/push-object-into-shopping-cart'
// import popObjectFromShoppingCart from '../components/contribute-form/reducers/pop-object-from-shopping-cart'
// import increaseCountOfObjectInShoppingCart from '../components/contribute-form/reducers/increase-count-of-object-in-shopping-cart'
// import decreaseCountOfObjectInShoppingCart from '../components/contribute-form/reducers/decrease-count-of-object-in-shopping-cart'
// import resetShoppingCart from '../components/contribute-form/reducers/reset-shopping-cart'

import * as actions from './actions'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------- Reducers
// ----------------------------------------------------------------------------
/** [description] */
// const updatePriceOfObjectInShoppingCart = (
//   defaultState,
//   previousState,
//   payload
// ) => {
//   const newObjectList = []
//   map(previousState.shoppingCartState.objectsList, (object) => {
//     if (object.routeSlug === payload.routeSlug) {
//       object.price = payload.price
//     }
//     newObjectList.push(object)
//   })

//   const thatTotalPriceOfObjects =
//     previousState.shoppingCartState.totalPriceOfObjects
//   const thisCountsOfObjects = previousState.shoppingCartState.objectsCounts
//   const thisTotalCountOfObjects =
//     previousState.shoppingCartState.totalCountOfObjects
//   const thisTotalPrice = []

//   map(newObjectList, (object) => {
//     thisTotalPrice.push(object.price * thisCountsOfObjects[object.routeSlug])
//   })

//   const thisTotalPriceOfObjects = thisTotalPrice.reduce((a, b) => a + b, 0)

//   return Object.assign({}, previousState, {
//     shoppingCartState: {
//       objectsList: newObjectList,
//       objectsCounts: thisCountsOfObjects,
//       totalCountOfObjects: thisTotalCountOfObjects,
//       totalPriceOfObjects: thisTotalPriceOfObjects,
//       thatTotalPriceOfObjects,
//       thisTotalPriceOfObjects,
//     },
//   })
// }

const defaultState = {
  counter: 0,
  mediaState: {
    currentWidth: 'w-lg',
    currentHeight: 'h-sm',
    currentOrientation: 'landscape',
    widthIsExtraExtraLarge: false,
    widthIsExtraLarge: false,
    widthIsLarge: true,
    widthIsMedium: false,
    widthIsSmall: false,
    widthIsExtraSmall: false,
    heightIsExtraExtraLarge: false,
    heightIsExtraLarge: false,
    heightIsLarge: false,
    heightIsMedium: false,
    heightIsSmall: true,
    heightIsExtraSmall: false,
    isPortrait: true,
    isLandscape: false,
    isRetina: true,
    point: 21,
  },
  headerState: {
    active: false,
  },
  websiteState: {
    mode: 'light',
    nav: 'fixed',
    typesize: 'md-type',
    typeface: 'classic-typeface',
  },
  shoppingCartState: {
    objectsList: [],
    objectsCounts: {},
    totalCountOfObjects: 0,
    totalPriceOfObjects: 0,
    thatTotalPriceOfObjects: 0,
    thisTotalPriceOfObjects: 0,
  },
  noticeQueueState: [],
  forwardData: {
    forward: false,
    customer_name: 'NA',
    customer_email: 'NA',
    customer_phone: 'NA',
    source: 'NA',
    purpose: 'NA',
    source_order_id: 'NA',
    payment_account_id: 'NA',
    amount: 'NA',
    currency: 'NA',
    return_url: 'NA',
  },
  audioPlayerState: {
    active: false,
    buzzsproutTag: 'NA',
  },
}

/**
  * rootReducer - Root reducer for this package.
  * Root reducer is not called directly, but is comubined and used by Redux to initialise and manage state.
  *
  * @example
    dispatch(someAction({...somePayload...}));
  *
  * @param {object} previousState - A simple object that contains previous state. Default value is an empty object.
  * @param {object} action - An action object of the nature { type: ACTION_KEYWORD, payload: payloadObject }
  * @version 0.0.1
  * @todo none
*/
const rootReducer = (previousState = defaultState, action) => {
  switch (action.type) {
    // case actions.SET_FORWARD_DATA:
    //   return Object.assign({}, previousState, {
    //     forwardData: action.payload,
    //   })
    // case actions.DELETE_FORWARD_DATA:
    //   return Object.assign({}, previousState, {
    //     forwardData: action.payload,
    //   })
    // case actions.RESET_SHOPPING_CART:
    //   return resetShoppingCart(defaultState, previousState, action.payload)
    // case actions.PUSH_OBJECT_INTO_SHOPPING_CART:
    //   return pushObjectIntoShoppingCart(
    //     defaultState,
    //     previousState,
    //     action.payload
    //   )
    // case actions.POP_OBJECT_FROM_SHOPPING_CART:
    //   return popObjectFromShoppingCart(
    //     defaultState,
    //     previousState,
    //     action.payload
    //   )
    // case actions.INCREASE_COUNT_OF_OBJECT_IN_SHOPPING_CART:
    //   return increaseCountOfObjectInShoppingCart(
    //     defaultState,
    //     previousState,
    //     action.payload
    //   )
    // case actions.UPDATE_PRICE_OF_OBJECT_IN_SHOPPING_CART:
    //   return updatePriceOfObjectInShoppingCart(
    //     defaultState,
    //     previousState,
    //     action.payload
    //   )
    // case actions.DECREASE_COUNT_OF_OBJECT_IN_SHOPPING_CART:
    //   return decreaseCountOfObjectInShoppingCart(
    //     defaultState,
    //     previousState,
    //     action.payload
    //   )
    case actions.PUSH_NOTICE:
      const newQueueWithPushedItem = [
        ...previousState.noticeQueueState,
        action.payload,
      ]
      return Object.assign({}, previousState, {
        noticeQueueState: newQueueWithPushedItem,
      })
    case actions.POP_NOTICE:
      const newQueueWithPoppedItem = filter(
        previousState.noticeQueueState,
        (item) =>
          item.message !== action.payload.message &&
          item.description !== action.payload.description
      )
      return Object.assign({}, previousState, {
        noticeQueueState: newQueueWithPoppedItem,
      })
    case actions.UPDATE_NOTICE_QUEUE:
      return Object.assign({}, previousState, {
        noticeQueueState: action.payload,
      })
    case actions.UPDATE_SHOPPING_CART:
      return Object.assign({}, previousState, {
        shoppingCartState: action.payload,
      })
    case actions.INCREASE:
      return Object.assign({}, previousState, {
        counter: action.payload,
      })
    case actions.DECREASE:
      return Object.assign({}, previousState, {
        counter: action.payload,
      })
    case actions.UPDATE_AUDIO_PLAYER_STATE:
      return Object.assign({}, previousState, {
        audioPlayerState: action.payload,
      })
    case actions.UPDATE_MEDIA_STATE:
      return Object.assign({}, previousState, {
        mediaState: action.payload,
      })
    case actions.UPDATE_SCREEN_SIZE_STATE:
      return Object.assign({}, previousState, {
        screenSizeState: action.payload,
      })
    case actions.UPDATE_HEADER_STATE:
      return Object.assign({}, previousState, {
        headerState: action.payload,
      })
    case actions.UPDATE_WEBSITE_STATE:
      return Object.assign({}, previousState, {
        websiteState: action.payload,
      })
    default:
      return previousState
  }
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default rootReducer
